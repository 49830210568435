<template>
  <div>
    <div v-loading="loading">
      <div class="flex justify-between items-center font-medium text-lg">
        <p>Step{{ curStep }}：{{ stepsConfig[curStep - 1].label }}</p>
        <p>{{ curStep }}/3</p>
      </div>
      <div class="divider" />

      <div v-if="curStep === 1">
        <p class="font-bold text-xl pb-[20px]">您希望達成怎麼樣的目標？</p>
        <div class="flex items-center flex-wrap" style="gap: 16px">
          <div
            v-for="option in targets"
            :key="option.label"
            class="target-option-item"
            :class="{
              active: formData.target === option.value,
            }"
            @click="onTargetChange(option.value)"
          >
            <span class="font-medium">{{ option.label }}</span>
          </div>
        </div>
      </div>

      <div v-if="curStep === 1 && formData.target === '增加業績'" style="margin-top: 48px">
        <p class="font-bold text-xl pb-[20px]">請選擇 AI 智能分群標籤</p>
        <div class="flex items-center flex-wrap" style="gap: 16px">
          <div
            v-for="tag in aiTags"
            :key="tag.id"
            class="tag-option-item"
            :class="{
              active: formData.tag === tag.name,
            }"
            @click="onTagChange(tag.name)"
          >
            {{ tag.name }}（{{ tag.memberCount }} 位）
          </div>
        </div>
      </div>

      <div v-if="curStep === 2">
        <p class="font-bold text-xl pb-[20px]">AI 已根據您選擇的目標，產生對應的行銷策略，請選擇喜歡的行銷策略：</p>
        <div class="flex flex-wrap" style="gap: 20px">
          <BeautyAIEventTemplateCard
            v-for="strategy in strategys"
            v-show="strategy.targets.includes(formData.target)"
            :key="strategy.title"
            class="relative"
            :class="{
              'card-selected': formData.strategy === strategy.title,
            }"
            :data="{
              img: strategy.img,
              title: strategy.title,
              description: strategy.description,
            }"
            @click.native="formData.strategy = strategy.title"
          />
        </div>
      </div>

      <BeautyAIEventTemplateGenerateResult
        v-if="curStep === 3"
        :contextData="formData"
      />
    </div>

    <PageFixedFooter>
      <BaseElButton v-if="curStep > 1" class="btn" plain @click="preStep">上一步</BaseElButton>
      <BaseElButton
        v-if="curStep < 3"
        class="btn"
        plain
        :disabled="disabledNext"
        :loading="loading"
        @click="nextStep"
      >
        下一步
      </BaseElButton>
      <BaseElButton v-if="curStep === 3" class="btn" plain @click="restart">重新開始</BaseElButton>
    </PageFixedFooter>
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue'
import BeautyAIEventTemplateCard from './components/BeautyAIEventTemplateCard.vue'
import BeautyAIEventTemplateGenerateResult from './components/BeautyAIEventTemplateGenerateResult.vue'
import { useFetch } from '@/use/fetch'
import { GetTags } from '@/api/memberTags'
import { useShop } from '@/use/shop'
import { filter } from 'lodash'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'

export default defineComponent({
  name: 'BeautyAIEventTemplateGenerator',
  components: { BeautyAIEventTemplateCard, BeautyAIEventTemplateGenerateResult, PageFixedFooter },
  setup (props) {
    const loading = ref(false)
    const curStep = ref(1)
    const aiTags = ref([])
    const { fetchAll } = useFetch()
    const { shopId } = useShop()
    const formData = reactive({
      target: null,
      tag: null,
      strategy: null,
    })
    const stepsConfig = [
      { label: '選擇目標' },
      { label: '選擇行銷策略' },
      { label: '行銷策略建議' },
    ]

    const targets = [
      { label: '培養顧客忠誠度', value: '培養顧客忠誠度' },
      { label: '獲取新客', value: '獲取新客' },
      { label: '增加業績', value: '增加業績' },
    ]

    const strategys = [
      {
        img: require('@/views/BeautyAIEventTemplateGenerator/assets/img/06.webp'),
        title: '好友推薦禮',
        description: '讓會員推薦好友加入，獲取新客創造會員增長',
        targets: ['獲取新客'],
      },
      {
        img: require('@/views/BeautyAIEventTemplateGenerator/assets/img/05.webp'),
        title: '註冊禮',
        description: '新會員註冊即可獲得好禮，刺激顧客加入會員獲取新客',
        targets: ['獲取新客'],
      },
      {
        img: require('@/views/BeautyAIEventTemplateGenerator/assets/img/03.webp'),
        title: '首次體驗禮',
        description: '首次體驗贈送好禮，吸引顧客初次體驗創造營收',
        targets: ['獲取新客'],
      },
      {
        img: require('@/views/BeautyAIEventTemplateGenerator/assets/img/03.webp'),
        title: '等級禮',
        description: '提供不同等級會員不同的優惠折扣或贈禮',
        targets: ['培養顧客忠誠度'],
      },
      {
        img: require('@/views/BeautyAIEventTemplateGenerator/assets/img/04.webp'),
        title: '生日禮',
        description: '提供生日的會員優惠與折扣，提升客戶黏性、尊榮感',
        targets: ['培養顧客忠誠度'],
      },
      {
        img: require('@/views/BeautyAIEventTemplateGenerator/assets/img/05.webp'),
        title: '累積消費獎勵',
        description: '',
        targets: ['培養顧客忠誠度'],
      },
      {
        img: require('@/views/BeautyAIEventTemplateGenerator/assets/img/02.webp'),
        title: '滿額贈',
        description: '消費滿一定金額提供優惠或贈禮方案，提高客單價',
        targets: ['增加業績'],
      },
      {
        img: require('@/views/BeautyAIEventTemplateGenerator/assets/img/01.webp'),
        title: '消費即贈',
        description: '消費提供優惠或贈禮方案，提高體驗意願',
        targets: ['增加業績'],
      },
      {
        img: require('@/views/BeautyAIEventTemplateGenerator/assets/img/01.webp'),
        title: '消費指定項目即贈',
        description: '消費指定項目提供優惠或贈禮，適合主打熱門項目者節慶活動',
        targets: ['增加業績'],
      },
    ]

    const disabledNext = computed(() => {
      if (formData.target === '增加業績' && !formData.tag) {
        return true
      }
      if (curStep.value === 1 && !formData.target) {
        return true
      }
      if (curStep.value === 2 && !formData.strategy) {
        return true
      }
      return false
    })

    const onTargetChange = (value) => {
      if (value !== '增加業績') formData.tag = null
      formData.target = value
    }

    const onTagChange = (value) => {
      formData.tag = value
    }

    const nextStep = async () => {
      await fakeLoading()
      if (curStep.value < 3) {
        curStep.value += 1
      }
    }

    const preStep = () => {
      if (curStep.value > 1) {
        curStep.value -= 1
      }
    }

    const restart = () => {
      curStep.value = 1
      formData.target = null
      formData.tag = null
      formData.strategy = null
    }

    const fakeLoading = async () => {
      loading.value = true
      // wait random seconds between 1 to 5
      const seconds = Math.floor(Math.random() * 5) + 1
      await new Promise((resolve) => setTimeout(resolve, seconds * 1000))
      loading.value = false
    }

    onBeforeMount(async () => {
      await fetchAll(GetTags, { shopId: shopId.value }, (res) => {
        const tags = filter(res, (tag) => tag.systemGroup === 'beautyAIMTag')
        // const tags = res
        aiTags.value = tags
      })
    })

    return { stepsConfig, curStep, targets, formData, strategys, onTargetChange, nextStep, preStep, restart, disabledNext, aiTags, onTagChange, loading }
  },
})
</script>

<style lang="postcss" scoped>
.divider {
  @apply w-full h-[2px] bg-[#ECECEC] mt-[16px] mb-[20px];
}

.target-option-item {
  @apply rounded-full p-[20px] bg-primary-30 cursor-pointer;
}

.tag-option-item {
  @apply rounded-full py-[8px] px-[20px] bg-primary-30 cursor-pointer;
}

.active {
  @apply bg-primary-100 text-white;
}

.card-selected {
}

.card-selected::after {
  content: '';
  @apply absolute top-0 left-0 right-0 bottom-0;
  @apply border-[4px] border-primary-100 rounded-[8px];
}

.btn {
  @apply min-w-[100px];
}
</style>
